<template>
  <standard-page :definition="$DEFINITIONS.provenance.newProcessProcessDefinition" :title="processDefinition.name" sub_title="Process Definition" v-if="processDefinition">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'available-process'}">Available Processes</router-link>
      </span>
      <span v-if="processDefinition">Process: {{processDefinition.name | truncate(10, '.....')}}</span>
    </template>
    <template v-slot:buttons>
      <button @click="addProcess" class="d-none d-xl-block float-right btn btn-orange rounded font-weight-bold" title="Add Process">
        <i class="fas fa-plus-square mg-r-10" />Start New Process
      </button>
        <button @click="addProcess" class="d-xl-none btn btn-icon float-right w-35 btn-orange rounded" title="Add Process">
            <i class="typcn typcn-document-add" />
        </button>
    </template>
    <template v-slot:content>

      <data-table :api="processDefinitionStepApi" :columns="processDefinitionStepColumns" :row-click="viewProcessDefinition" :server-side="true" list-key="process_definition_steps" table-id="process_definition_step_table" total-key="total">
      </data-table>

      <div class="az-content-label mg-b-5 mg-t-30">Existing Processes</div>
      <p class="mg-b-10" v-html="$DEFINITIONS.provenance.existingProcess"></p>

      <data-table :api="processApi" :columns="processColumns" @ready="onReady" :row-click="view" :server-side="true" list-key="processes" table-id="process_table" total-key="total">
      </data-table>
    </template>
  </standard-page>
</template>

<script>

export default {
  name: "Processes",
  props: ['registryid', 'processdefinitionid'],
  data() {
    return {
      processDefinition: null,
      processDefinitionStepApi: `${this.$provenance.defaults.baseURL}/provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps`,
      processApi: `${this.$provenance.defaults.baseURL}/provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes`,
      processDefinitionStepColumns: [
        { type: 'order' },
        { type: 'name' }, {
          title: 'Attestors',
          data: 'attestors',
          orderable: false,
          render: data => data.length,
        },
      ],

      processColumns: [
        { type: 'name' },
        { type: 'provenanceProcessStatus' },
        { type: 'created' },
        { type: 'extrinsic' },
        {
          type: 'action',
          defaultContent: `<div class="offset-2 btn-group btn-icon-list">
                                ${this.$StandardButtons.renameButton('Rename Process')}
                                <span class="text-dark qr mg-r-10" style="cursor: pointer" title="View QR">
                                    <i class="fa fa-qrcode fa-lg"></i>
                                </span>
                           </div>`
        }
      ],

    };
  },
  mounted() {
    this.getProcessDefinition();
  },
  methods: {
      onReady(table) {
          this.table = table;
          let self = this;

          $(async function () {
              $('#process_table tbody').on('click', '.qr', function () {
                  let process = table.row($(this).parents('tr')).data();
                  self.$router.push({name: 'process-qr-print',  params: { registryid: process.registry, processdefinitionid: process.process_definition, processid: process.process }})
              });
          });
      },
    async getProcessDefinition() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}`);
        this.processDefinition = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Process Definition", "Error");
      } finally {

      }
    },
    viewProcessDefinition(row) {
        console.log(row)
      this.$router.push({ name: 'view-process-definition-step-by-attestor', params: { registryid: this.registryid, processdefinitionid: this.processdefinitionid, stepid: row.process_definition_step } });
    },
    view(process) {
        this.$router.push({
            name: 'ongoing-process-steps', params: {
                processid: process.process
            }
        })
      // if (process.status === 'InProgress') {
      //   this.$router.push({ name: 'ongoing-process-steps', params: { registryid: this.registryid, processdefinitionid: this.processdefinitionid, processid: process.process } });
      // } else if (process.status === 'Completed') {
      //   this.$router.push({ name: 'complete-process-steps', params: { registryid: this.registryid, processdefinitionid: this.processdefinitionid, processid: process.process } });
      // }
    },
    addProcess() {
      this.$router.push({ name: 'new-process' });
    },
    async updateProcess(process, name) {
      try {
        await this.$provenance.patch(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${process.process}`, { name });
      } catch (e) {
        this.$toastr.e("Process updating failed", 'Error');
      }
    }
  }
}
</script>

<style scoped>
</style>